<template>
  <!-- 品名选择 -->
  <el-dialog
    class="chooseMatterWeight"
    title="请选择商品"
    :visible.sync="dialogTableVisible"
    :close-on-click-modal="false"
  >
    <div class="flexBox">
      <ProductName :default-value="defaultValue" @getProduct="getProduct" />
      <!-- 选择材质 -->
      <el-select v-model="formInline.materials" size="small" filterable placeholder="请选择材质" @focus="judgment" @change="$forceUpdate()">
        <el-option
          v-for="(item, ind) in materialsArr"
          :key="ind"
          :label="item.materials"
          :value="item.materials"
        />
      </el-select>
      <el-select v-model="formInline.specs" size="small" filterable placeholder="请选择规格" @focus="judgment" @change="$forceUpdate()">
        <el-option
          v-for="(item, ind) in specificationArr"
          :key="ind"
          :label="item.specs"
          :value="item.specs"
        />
      </el-select>
      <div class="button">
        <el-button
          size="small"
          class="high-button"
          type="primary"
          @click="getdata(false)"
        >
          搜索
        </el-button>
        <el-button
          size="small"
          class="high-button"
          type="primary"
          @click="resetForm"
        >
          重置
        </el-button>
      </div>
    </div>
    <p class="total-box">
      <span>找不到商品？请联系运营人员添加</span>
      已选择{{ selectArr.length||0 }}条
    </p>
    <el-table
      ref="multipleTable"
      border
      :data="gridData"
      max-height="380"
      @select="selectionRow"
      @select-all="selectionAllRow"
    >
      <template slot="empty">
        <span>您选择的品名未有商品，请重新选择或者进行添加</span>
      </template>
      <el-table-column v-if="showRadio" label=" " width="42">
        <template slot-scope="scope">
          <el-radio v-model="value" :label="scope.row">
            .
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column v-else type="selection" width="55" />
      <el-table-column property="goodsName" label="品种" />
      <el-table-column property="materials" label="材质" />
      <el-table-column property="specs" label="规格" />
      <el-table-column property="productions" label="产地" />
      <el-table-column property="weights" label="件重(吨)" />
    </el-table>
    <!-- 分页 -->
    <Pagination
      :params="formInline"
      :total="total"
      :in-article="gridData.length"
      :get-data-list="getdata"
    />

    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogTableVisible = false">
        取 消
      </el-button>
      <el-button size="small" type="primary" @click="getCheckId">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { goodsWeightsListByGoodsId } from '@/http/agentApi/common'
import { getGoodsMaterialsList, getGoodsSpecsList } from '@/http/custApi/common'
import Pagination from '@/components/Pagination'
import ProductName from '@/components/ProductName'
export default {
  components: { Pagination, ProductName },
  props: {
    id: {
      default: '',
      type: String
    },
    showRadio: {
      default: true,
      type: Boolean
    },
    selectedArr: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      gridData: [],
      value: '',
      total: 0,
      selectArr: [],
      pnameArr: [],
      defaultValue: [],
      isExecute: false,
      formInline: {
        pageSize: 10,
        pageNum: 1,
        materials: '',
        specs: ''
      },
      materialsArr: [],
      specificationArr: [],
      dialogTableVisible: false
    }
  },
  watch: {
    dialogTableVisible: {
      handler(newVal) {
        if (newVal) {
          this.getdata()
          this.value = null
          this.selectArr = [...this.selectedArr]
          return
        }
        if (this.selectedArr.length === 0 && this.$refs['multipleTable']) this.$refs['multipleTable'].clearSelection()
      }
    },
    gridData: {
      handler() {
        this.$nextTick(() => {
          this.selectArr.forEach(row => {
            const obj = this.gridData.find(item => row.id === item.id || row.goodsWeightsId === item.id)
            if (obj && obj.id) this.$refs['multipleTable'].toggleRowSelection(obj, true)
          })
        })
      },
      deep: true,
      immediate: true
    },
    id: {
      handler(newVal) {
        if (newVal) {
          this.formInline = {
            pageSize: 10,
            pageNum: 1,
            goodsId: newVal
          }
          this.getdata()
        }
      }
    }
  },
  mounted() {
    // 获取票品名
    this.formInline = {
      pageSize: 10,
      pageNum: 1
    }
    this.getdata()
  },
  methods: {
    // 重置按钮
    resetForm() {
      this.defaultValue = []
      this.materialsArr = []
      this.getdata('reset')
    },
    // 点击判断是否选了品名
    judgment() {
      if (!this.formInline.goodsId) this.$message.error('请先选择品名')
    },
    // 修改品名的时候
    getProduct(obj) {
      this.formInline.specs = ''
      this.formInline.materials = ''
      if (obj.value.length !== 3) {
        this.materialsArr = []
        this.specificationArr = []
        return
      }
      this.formInline.goodsId = obj.value[2] || ''
      getGoodsMaterialsList({ state: 1, categoryId: obj.value[0], varietyId: obj.value[1], goodsId: obj.value[2] }, res => {
        this.materialsArr = [...res.data]
      })
      getGoodsSpecsList({ state: 1, categoryId: obj.value[0], varietyId: obj.value[1], goodsId: obj.value[2], pageSize: 1000 }, res => {
        this.specificationArr = [...res.data]
      })
    },
    // 全选全不选
    selectionAllRow(arr) {
      // 不选的时候   就把id从列表中拿出来   从已经选了的中剔除出来
      if (arr.length === 0) {
        const idArr = []
        this.gridData.forEach(item => {
          idArr.push(item.id)
        })
        this.selectArr = this.selectArr.filter(item => !idArr.includes(item.id))
        return
      }
      // 全选的时候  进行拼接然后去重
      this.selectArr = this.unique(this.selectArr.concat(arr))
    },
    // 数组去重
    unique(arr) {
      const res = new Map()
      return arr.filter((a) => !res.has(a.id) && res.set(a.id, 1))
    },
    selectionRow(arr, row) {
      let flag = true
      // 小于的时候就是勾选为false   就在删除的中加上一条  否则减去一条
      this.selectArr.forEach((item, index) => {
        if (item.id === row.id) {
          this.selectArr.splice(index, 1)
          flag = false
        }
      })
      if (flag) this.selectArr.push(row)
    },
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      goodsWeightsListByGoodsId(this.formInline, (res) => {
        this.gridData = res.data.pageData.map(item => {
          item.weights = item.weights || '-'
          return item
        })
        this.total = res.data.total
      })
    },
    getCheckId() {
      if (!this.value && this.showRadio) {
        this.$message.error('请选择一个！')
        return
      }
      if (!this.showRadio && this.selectArr.length === 0) {
        this.$message.error('请选择一个！')
        return
      }
      this.dialogTableVisible = false
      this.$emit('getCheckId', this.showRadio ? this.value : this.selectArr)
    }
  }
}
</script>

<style lang="scss">
.chooseMatterWeight {
  .el-dialog{
    min-width: 960px;
  }
  .total-box{
    display: flex;
    justify-content: space-between;
    text-align: right;
    padding-bottom: 6px;
  }
  // .el-table__header th .el-checkbox__input{
  //   display: none;
  // }
  .flexBox{
    display: flex;
    margin-bottom: 18px;
    align-items: center;
    justify-content: space-between;
  }
  .el-select{
    width: 220px;

  }
  .el-radio__label {
    display: none;
  }
  .dialog-footer {
    text-align: center;
  }
  .el-table__empty-text {
    width: 100%;
  }
}
</style>
